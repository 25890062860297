import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal" ];

  static values = {
    expirationDate: String
  }

  connect() {
    this.startTimer();
  }

  disconnect() {
    clearTimeout(this.timer);
  }

  startTimer() {
    this.timer = setTimeout(() => {
      if (this.expirationDate() < Date.now()) {
        this.performAutoSignOut();
      } else {
        this.startTimer();
      }
    }, 10 * 1000);
  }

  performAutoSignOut() {
    if (this.hasModalTarget) {
      $(this.modalTarget).modal({
        keyboard: false,
        backdrop: "static"
      }).modal("show");
    }
  }

  expirationDate() {
    return Date.parse(this.expirationDateValue);
  }
}
