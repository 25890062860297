import { Controller } from "stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "modalContainer",
    "modalBody",
  ]

  connect() {
    $(this.modalContainerTarget).modal({show: false});
    $(this.modalContainerTarget).on('hidden.bs.modal', (e) => {
      this.modalBodyTarget.innerHTML = "";
    });
  }

  disconnect() {
    $(this.modalContainerTarget).modal('hide');
  }

  async openModal(evt) {
    evt.preventDefault();
    $(this.modalContainerTarget).modal("show");
    const url = evt.currentTarget.href;
    const request = new FetchRequest('get', url)
    const response = await request.perform();
    if (response.ok) {
      const body = await response.html;
      this.modalBodyTarget.innerHTML = body;
    }
  }
}
