import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "select",
    "textField"
  ]

  connect() {
    this.update()
  }

  update(event) {
    if (this.selectTarget.selectedOptions[0] && this.selectTarget.selectedOptions[0].dataset.additionalText == "true") {
      this.textFieldTarget.disabled = false
      this.textFieldTarget.removeAttribute("disabled")
    } else {
      this.textFieldTarget.disabled = true
      this.textFieldTarget.setAttribute("disabled", "disabled")
    }
  }
}
