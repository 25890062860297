import { Controller } from "stimulus"
import { selectize } from "selectize"
import jquery from "jquery"

export default class extends Controller {
  connect() {
    let plugins = []

    if (this.data.get("backspace") == "true") {
      plugins.push("restore_on_backspace")
    }

    jquery(this.element).selectize({
      create: this.data.get("create") == "true",
      createOnBlur: true,
      plugins: plugins
    })
  }
}
