import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "trigger", "toggleable" ];

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.triggerTarget.checked) {
      this.toggleableTarget.classList.remove("hidden");
    } else {
      this.toggleableTarget.classList.add("hidden");
    }
  }
}
