import { Controller } from "stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"

export default class extends Controller {
  static targets = [
    "nameField",
    "clientNumberField",
    "additionField",
    "cityField",
    "countryField",
    "zipField",
    "streetField",
    "districtField",
    "noteField",
  ]

  connect() {
    if (this.data.get("enabled") == "1") {
      new autoComplete({
        data: {
          src: async () => {
            const query = this.nameFieldTarget.value;
            const result = await $.ajax({
              type: "GET",
              url: this.data.get("url"),
              data: {q: query},
            });
            return result.shipment_addresses;
          },
          key: ['name'],
          cache: false
        },
        selector: () => { return this.nameFieldTarget },
        searchEngine: (query, record) => {
          return true;
        },
        observer: false,
        threshold: 0,
        trigger: {
      		event: ["input", "focusin"],
          condition: (query) => {
            return true;
          }
      	},
        debounce: 300,
        maxResults: 10,
        highlight: {
          render: true,
        },
        resultItem: {
          content: (data, source) => {
            source.innerHTML = `<span>${data.value.name || ""}</span><br /><span>${data.value.street || ""}</span><br /><span title="${data.value.country_name || ""}${data.value.country_name ? " -" : ""} ${data.value.zip || ""} ${data.value.city || ""} ${data.value.district ? "- " : ""}${data.value.district || ""}">${data.value.country_name || ""}${data.value.country_name ? " -" : ""} ${data.value.zip || ""} ${data.value.city || ""} ${data.value.district ? "- " : ""}${data.value.district || ""}</span>`;
          },
          element: "li"
        },
        onSelection: (feedback) => {
          this.selectItem(feedback.selection.value);
        }
      });
    }
  }

  selectItem(shipmentAddress) {
    if (this.hasClientNumberFieldTarget) {
      this.clientNumberFieldTarget.value = shipmentAddress.client_number;
    }
    if (this.hasDistrictFieldTarget) {
      this.districtFieldTarget.value = shipmentAddress.district;
    }
    if (this.hasZipFieldTarget) {
      this.zipFieldTarget.value = shipmentAddress.zip;
    }
    if (this.hasStreetFieldTarget) {
      this.streetFieldTarget.value = shipmentAddress.street;
    }
    if (this.hasCountryFieldTarget) {
      this.countryFieldTarget.value = shipmentAddress.country;
    }
    if (this.hasAdditionFieldTarget) {
      this.additionFieldTarget.value = shipmentAddress.addition;
    }
    if (this.hasCityFieldTarget) {
      this.cityFieldTarget.value = shipmentAddress.city;
    }
    if (this.hasNoteFieldTarget) {
      this.noteFieldTarget.value = shipmentAddress.note;
    }
    if (this.hasNameFieldTarget) {
      this.nameFieldTarget.value = shipmentAddress.name;
    }
  }
}
